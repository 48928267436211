
export default {
	name: 'subjects',
	data() {
		return {
			sex: '',
			work: {
				projectName: '', //标题
				frontWorkName: '',
				complex: '',//计划外访视||计划内访视
			},
			partAlert: false,
			outId: "",
			bigImg: false,//控制大图片的显示
			bigImgUrl: '',
			liDiv: -1,//li详情图片
			alertTitle: '知情同意书', //弹框title
			list: "",
			color(value) {
				if (value < 20) {
					return '#F73B36'
				} else if (value >= 20 && value < 80) {
					return '#FFAE00'
				} else {
					return '#36B822'
				}
			},
			ulList: '',
			subjectsList: [{
				projectClass: '常规',
				checkItem: '病史、手术史和癌症史 ',
				id: '1',
				time: '方案A',
				state: '初始状态',
			}],
			typeArr: ['筛选期', '试验期', '终止期', '随访期']
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getCard()
	},

	methods: {
		getCard() {
			var _this = this;
			_this.$http.post('/API/PLAT/findWorkInfo', {
				workId: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response.data)
						_this.work = response.data.data;
						_this.list = response.data.data.examineList;
					}
				})
		},
		clickCard(checkid, workid, checkTemplateId) {
			var _this = this;
			this.partAlert = true;
			_this.$http.post('/API/PROJECT/patListByCheckIdAmdWorkId', {
				workid: workid,
				checkid: checkid,
				checkTemplateId: checkTemplateId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.alertTitle = response.data.data.checkName;
						_this.ulList = response.data.data.patList;
					}
				})
		},
		clickPhoto(url) {
			this.bigImg = true;
			this.bigImgUrl = url;
			//				console.log(this.bigImg)
			//				console.log(this.bigImgUrl)
		},
		toPl() {//面包屑
			this.$router.go(-1)
		},
		no() {
			this.removeAlert = false;
		},
		closeAlert() {//关闭弹框
			this.partAlert = false;
		},
		closeBigImg() {//关闭大图片
			this.bigImg = false;
		},
		openLi(index) {//打开li详情
			this.liDiv = index;
		},
		closeLi() {//关闭li详情
			this.liDiv = -1;
		}
	}
}
